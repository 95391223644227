import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { RoadmapTask } from '../01-models';
import { map } from "rxjs/operators";
import { OfflineManagerService } from './offline-manager.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const ROADMAP_TASKS_KEY = 'roadmap-tasks';

@Injectable({
  providedIn: 'root'
})
export class RoadmapTaskService {
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  getRoadmapTasks(): Observable<RoadmapTask[]> {
    const url = `${API_URL}/${VERSION}/roadmaptask/roadmaptasks`;
    return this.http.get<RoadmapTask[]>(url).pipe(
      map(roadmapTasks => {
        this.storageService.set(ROADMAP_TASKS_KEY, roadmapTasks);
        return roadmapTasks;
      })
    );
  }

  getRoadmapTasksByRoadmapId(id: string, companyId: number, workplaceNum: number): Observable<RoadmapTask[]> {
    return from(this.storageService.get(ROADMAP_TASKS_KEY)).pipe(
      map(roadmapTasks => roadmapTasks.filter(roadmapTask => 
        roadmapTask.roadmapId == id 
        && roadmapTask.companyId === companyId 
        && roadmapTask.workplaceNum === workplaceNum))
    );
  }

  getRoadmapTasksByRoadmapIdAndDay(id: string, companyId: number, workplaceNum: number, day: string): Observable<RoadmapTask[]> {
    return from(this.storageService.get(ROADMAP_TASKS_KEY)).pipe(
      map(roadmapTasks => roadmapTasks.filter(roadmapTask => 
        roadmapTask.roadmapId == id 
        && roadmapTask.companyId === companyId 
        && roadmapTask.workplaceNum === workplaceNum 
        && roadmapTask[day] > 0))
    );
  }

  getRoadmapTaskById(id: number): Observable<RoadmapTask> {
    return from(this.storageService.get(ROADMAP_TASKS_KEY)).pipe(
      map(roadmapTasks => roadmapTasks.find(roadmapTask => roadmapTask.id === id))
    );
  }

  delete(roadmapTaskId: number) {
    const url = `${API_URL}/${VERSION}/roadmaptask/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', roadmapTaskId);
    } else {
      this.http.post(url, roadmapTaskId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', roadmapTaskId);
        }
      );
    }
    return from(this.deleteRoadmapTask(roadmapTaskId));
  }

  deleteRoadmapTask(roadmapTaskId: number) {
    return this.storageService.get(ROADMAP_TASKS_KEY).then(roadmapTasks => {
      const index = roadmapTasks.findIndex(roadmapTask => roadmapTask.id === roadmapTaskId);
      roadmapTasks.splice(index, 1);
      return this.storageService.set(ROADMAP_TASKS_KEY, roadmapTasks);
    });
  }

  update(roadmapTask: RoadmapTask) {
    const url = `${API_URL}/${VERSION}/roadmaptask/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', roadmapTask);
    } else {
      this.http.post(url, roadmapTask).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', roadmapTask);
        }
      );
    }
    return from(this.updateRoadmapTask(roadmapTask));
  }

  updateRoadmapTask(editedRoadmapTask: RoadmapTask) {
    return this.storageService.get(ROADMAP_TASKS_KEY).then((roadmapTasks: any[]) => {
      const index = roadmapTasks.findIndex(p => p.id === editedRoadmapTask.id);
      roadmapTasks[index] = editedRoadmapTask;
      return this.storageService.set(ROADMAP_TASKS_KEY, roadmapTasks);
    });
  }

  add(roadmapTask: RoadmapTask) {
    const url = `${API_URL}/${VERSION}/roadmaptask/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', roadmapTask);
    } else {
      this.http.post(url, roadmapTask).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', roadmapTask);
        }
      );
    }
    return from(this.addRoadmapTask(roadmapTask));
  }

  addRoadmapTask(newRoadmapTask: RoadmapTask) {
    return this.storageService.get(ROADMAP_TASKS_KEY).then((roadmapTasks: any[]) => {
      if (roadmapTasks) {
        roadmapTasks.unshift(newRoadmapTask);
        return this.storageService.set(ROADMAP_TASKS_KEY, roadmapTasks);
      }
      return this.storageService.set(ROADMAP_TASKS_KEY, [newRoadmapTask]);
    });
  }
}
