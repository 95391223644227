import { Injectable } from '@angular/core';
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Offer } from '../01-models';
import { map } from "rxjs/operators";
import { StorageService } from './storage.service';

const OFFERS_KEY = 'offers';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
    ) { }

  getOffers(): Observable<Offer[]> {
    const url = `${API_URL}/${VERSION}/offer/offers`;
    return this.http.get<Offer[]>(url).pipe(
      map(offers => {
        this.storageService.set(OFFERS_KEY, offers);
        return offers;
      })
    );
  }

  getOffersByServiceId(serviceId: string): Observable<Offer[]> {
    return from(this.storageService.get(OFFERS_KEY)).pipe(
      map(offers => offers.filter(offer => offer.serviceId == serviceId))
    );
  }

}