import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { importsStandalone } from 'src/app/05-shared/utils/ImportsStandalone';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController, private translate: TranslateService) { }

  present(key: string, params?: any) {
    lastValueFrom(
      this.translate.get(key, params)
    ).then((toastContent: string) => {
      this.presentToast(toastContent)
    }).catch((error) => {
      console.error(error);
    });
  }

  error(text: string) {
    this.presentError(text);
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 750,
      position: 'top'
    });
    try {
      await toast.present();
    } catch (error) {
      console.log(error);
    }
    
  }

  async presentError(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000,
      position: 'top'
    });
    toast.present();
  }
}
