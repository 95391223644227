import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { importsStandalone } from 'src/app/05-shared/utils/ImportsStandalone';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = new BehaviorSubject<{
    isLoading: boolean,
    message: string
  }>({
    isLoading: false,
    message: 'Actualizando datos ...'
  });
  message: string;

  present(message?: string) {
    this.isLoading.next({ isLoading: true, message: message ? message : 'Actualizando datos ...' });
  }

  closeLoading() {
    this.isLoading.next({ isLoading: false, message: 'Actualizando datos ...' });
  }
}
