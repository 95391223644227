import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AlertService {
  private subject = new Subject<any>();

  constructor(
  ) {
  } 

  error(message: string) {
    this.subject.next({ type: "error", text: message });
  }

  info(message: string) {
    this.subject.next({ type: "info", text: message });
  }

  success(message: string) {
    this.subject.next({ type: "success", text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
