import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { Dependence } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const DEPENDENCES_KEY = 'dependences';

@Injectable({
  providedIn: 'root'
})
export class DependenceService {
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  add(dependence: Dependence) {
    const url = `${API_URL}/${VERSION}/dependence/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', dependence);
    } else {
      this.http.post(url, dependence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', dependence);
        }
      );
    }
    return from(this.addDependence(dependence));
  }

  addDependence(dependence: Dependence) {
    return this.storageService.get(DEPENDENCES_KEY).then((dependences: Dependence[]) => {
      if (dependences) {
        dependences.unshift(dependence);
        return this.storageService.set(DEPENDENCES_KEY, dependences);
      }
      return this.storageService.set(DEPENDENCES_KEY, [dependence]);
    });
  }

  update(dependence: Dependence) {
    const url = `${API_URL}/${VERSION}/dependence/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', dependence);
    } else {
      this.http.post(url, dependence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', dependence);
        }
      );
    }
    return from(this.updateDependence(dependence));
  }
      
  updateDependence(dependence: Dependence){
    return this.storageService.get(DEPENDENCES_KEY).then((dependences: Dependence[]) => {
      const index = dependences.findIndex(dependences => dependences.id === dependence.id);
      dependences[index] = dependence;
      return this.storageService.set(DEPENDENCES_KEY, dependences);
    });
  }

  getDependences(): Observable<Dependence[]> {
    const url = `${API_URL}/${VERSION}/dependence/dependences`;
    return this.http.get<Dependence[]>(url).pipe(
      map(dependences => {
        this.storageService.set(DEPENDENCES_KEY, dependences);
        return dependences;
      })
    );
  }

  getAllDependences(): Observable<Dependence[]> {
    return from(this.storageService.get(DEPENDENCES_KEY));
  }

  getDependenceById(id: number): Observable<Dependence> {
    return from(this.storageService.get(DEPENDENCES_KEY)).pipe(
      map(dependences => dependences.find((dependence:Dependence) => dependence.id == id))
    );
  }

  getDependencesByWorkplace(companyId: number, workplaceNum: number): Observable<Dependence[]> {
    return from(this.storageService.get(DEPENDENCES_KEY)).pipe(
      map(dependences => {
        const depencesFilter = dependences.filter((dependence:Dependence) => dependence.companyId == companyId && dependence.workplaceNum == workplaceNum);
        return depencesFilter;
      })
    );
  }

  delete(dependneceId: number) {
    const url = `${API_URL}/${VERSION}/dependence/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', dependneceId);
    } else {
      this.http.post(url, dependneceId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', dependneceId);
        }
      );
    }
    return from(this.deleteDependence(dependneceId));
  }

  deleteDependence(dependneceId: number) {
    return this.storageService.get(DEPENDENCES_KEY).then(dependences => {
      const index = dependences.findIndex((dependence:Dependence) => dependence.id === dependneceId);
      dependences.splice(index, 1);
      return this.storageService.set(DEPENDENCES_KEY, dependences);
    });
  }
}
