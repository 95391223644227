import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom, of } from "rxjs";
import { Ticket } from '../01-models';
import { map } from "rxjs/operators";
import { LoadingService } from "./loading.service";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { GalleryMedia } from '../01-models/gallery-media';
import { MEDIA } from '../05-shared/utils/Contanst';
import { tick } from '@angular/core/testing';
import { Filesystem, Directory, WriteFileResult, FileInfo, StatResult } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Capacitor } from '@capacitor/core';


const TICKETS_KEY = 'tickets';
const TICKETS_IMAGE_KEY = 'tickets-image';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService,
    public loadingService: LoadingService,
    private fileOpener: FileOpener
  ) { }

  getTickets(): Observable<Ticket[]> {
    const url = `${API_URL}/${VERSION}/ticket/tickets`;
    return this.http.get<Ticket[]>(url).pipe(
      map(tickets => {
        this.storageService.set(TICKETS_KEY, tickets);
        return tickets;
      })
    );
  }

  getAllTickets(): Promise<Ticket[]> {
    return this.storageService.get(TICKETS_KEY);
  }

  getTicketById(id: number): Observable<Ticket> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.find(ticket => ticket.id == id))
    );
  }

  getTicketByTmpId(tmpId: string): Observable<Ticket> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.find(ticket => ticket.tmpTktId == tmpId))
    );
  }

  getTicketsByWorkplace(companyId: number, workplaceNum: number): Observable<Ticket[]> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.filter(ticket => ticket.companyId == companyId && ticket.workplaceNum == workplaceNum))
    );
  }

  async add(ticket: Ticket): Promise<Ticket> {
    const url = `${API_URL}/${VERSION}/ticket/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
      return this.addTicket(ticket);
    } else {
      try {
        const ticketNew = await lastValueFrom(this.http.post(url, ticket));
        await this.addTicket(ticketNew);
        return ticketNew;
      } catch (error) {
        //console.log(error)
        this.toastService.error(error.message);
        this.offlineManager.storeRequest(url, 'POST', ticket);
      }
    }
  }

  addImage(ticket: Ticket, images: GalleryMedia[]) {
    const url = `${API_URL}/${VERSION}/GalleryMedia/add`;
    const formData = new FormData();
    for (let image of images) {

      const imageGalleryMedia: GalleryMedia = {
        file: image.file,
        fileName: image.fileName,
        mediaType: MEDIA.IMAGE,
        mediaDescription: image.mediaDescription,
        mediaStatus: 'vigente',
        relatedTable: 'ticket',
        relatedTableId: ticket.id,
        modifiedBy: image.modifiedBy
      }

      formData.append('file', imageGalleryMedia.file);
      formData.append('fileName', imageGalleryMedia.fileName);
      formData.append('mediaType', imageGalleryMedia.mediaType);
      formData.append('mediaDescription', imageGalleryMedia.mediaDescription);
      formData.append('mediaStatus', imageGalleryMedia.mediaStatus);
      formData.append('relatedTable', imageGalleryMedia.relatedTable);
      formData.append('relatedTableId', imageGalleryMedia.relatedTableId.toString());
      formData.append('modifiedBy', imageGalleryMedia.modifiedBy);
    }
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', formData);
    } else {
      lastValueFrom(this.http.post(url, formData
      )).then(
        response => {
          //console.log(response)
        }).catch(error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', formData);
        }
        );
    }
    //return from(this.addImageByTicketId(ticket.id, images));
    // TODO: Implmentar metodo para guardar en el storage local
    return of(true);
  }

  convertToFile(image: string, name: string) {
    const byteCharacters = atob(image.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], name, { type: 'image/jpeg' });
  }

  addTicket(newTicket: Ticket) {
    return this.storageService.get(TICKETS_KEY).then(async (tickets: any[]) => {
      if (tickets) {
        tickets.unshift(newTicket);
        await this.storageService.set(TICKETS_KEY, tickets);
        return newTicket;
      }
      await this.storageService.set(TICKETS_KEY, [newTicket]);
      return newTicket;
    });
  }

  addImageByTicketId(id: number, image: { name: string, imageBase64: string }[]) {
    /**return this.storageService.get(TICKETS_IMAGE_KEY).then((tickets: {id: number, image: {name: string, imageBase64: string}[]}[]) => {
      if (tickets) {
        tickets.unshift({ id, image });
        return this.storageService.set(TICKETS_IMAGE_KEY, tickets);
      }
      return this.storageService.set(TICKETS_IMAGE_KEY, [{ id, image }]);
    });**/
  }

  update(ticket: Ticket) {
    const url = `${API_URL}/${VERSION}/ticket/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {
      this.http.post(url, ticket).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
        }
      );
    }
    return from(this.updateTicket(ticket));
  }

  updateTicket(editedTicket: Ticket) {
    return this.storageService.get(TICKETS_KEY).then((tickets: any[]) => {
      const index = tickets.findIndex(tickets => tickets.id === editedTicket.id);
      tickets[index] = editedTicket;
      return this.storageService.set(TICKETS_KEY, tickets);
    });
  }

  delete(ticketId: number) {
    const url = `${API_URL}/${VERSION}/ticket/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticketId);
    } else {
      this.http.post(url, ticketId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticketId);
        }
      );
    }
    return from(this.deleteTicket(ticketId));
  }

  deleteTicket(ticketId: number) {
    return this.storageService.get(TICKETS_KEY).then(tickets => {
      const index = tickets.findIndex(ticket => ticket.id === ticketId);
      tickets.splice(index, 1);
      return this.storageService.set(TICKETS_KEY, tickets);
    });
  }

  checkImageExists(imageUrl: string): Promise<boolean> {
    return lastValueFrom(this.http.get(imageUrl, { observe: 'response' }))
      .then(response => response.status === 200)
      .catch(() => false);
  }

  async generatePDF(ticketId: number) {
    const apiUrl = `${API_URL}/${VERSION}/ticket/${ticketId}`;

    this.loadingService.present("Generando PDF...");

    this.http.get(apiUrl, { responseType: 'blob' }).subscribe({
      next: async (response: Blob) => {
        if (Capacitor.getPlatform() === 'android') {
          // Guardar y abrir en Android
          await this.saveAndOpenFile(response, ticketId);
        } else {
          // Descargar en Web
          // this.downloadFileWeb(response, ticketId);
          // Abrir en una nueva pestaña
          this.openFileInNewTabWeb(response);
        }
        this.loadingService.closeLoading();
      },
      error: (error) => {
        console.error('Error descargando el archivo:', error);
        this.loadingService.closeLoading();
      }
    });
  }

  async viewPDF(ticketId: number) {
    const fileName = `Ticket_${ticketId}.pdf`;

    if (Capacitor.getPlatform() === 'android') {
      const fileExists = await this.checkIfFileExists(fileName);

      if (fileExists) {
        console.log('El archivo ya existe, abriéndolo directamente...');
        this.openFile(fileExists);
        return; // Salir de la función, no es necesario regenerar
      } else {
        this.generatePDF(ticketId);
      }
    } else {
      this.generatePDF(ticketId);
    }

  }

  public async checkIfFileExists(fileName: string): Promise<string | null> {
    try {
      const statResult: StatResult = await Filesystem.stat({
        path: fileName,
        directory: Directory.Documents,
      });

      // Crear un objeto FileInfo basado en los datos devueltos por Filesystem.stat
      const fileInfo: FileInfo = {
        name: fileName, // Aquí utilizamos el nombre proporcionado en la llamada
        type: 'file', // `Filesystem.stat` no devuelve el tipo, asumimos que es un archivo
        size: statResult.size ?? 0, // Tomar el tamaño si está disponible
        ctime: statResult.ctime, // Hora de creación si está disponible
        mtime: statResult.mtime, // Hora de modificación
        uri: statResult.uri, // URI completo del archivo
      };

      console.log('Archivo encontrado:', fileInfo);
      return fileInfo.uri;
    } catch (error) {
      console.log('El archivo no existe:', error);
      return null; // Retornar null si no existe
    }
  }

  private async openFile(filePath: string) {

    console.log("Esta es la ruta: ", filePath);
    try {
      // Verifica si el prefijo 'file://' está presente y remuévelo
      const pathToOpen = filePath.startsWith('file://') ? filePath.replace('file://', '') : filePath;

      console.log(pathToOpen);
      //const filePath = `${Directory.Documents}/${fileName}`;
      await this.fileOpener.open(pathToOpen, 'application/pdf');
      console.log('Archivo abierto correctamente');
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
    }
  }

  private downloadFileWeb(response: Blob, ticketId: number) {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Ticket_${ticketId}.pdf`; // Nombre del archivo
    a.click();
    window.URL.revokeObjectURL(url); // Limpia la URL temporal
  }

  private openFileInNewTabWeb(response: Blob) {
    const url = window.URL.createObjectURL(response);
    // Abrir el archivo en una nueva pestaña
    window.open(url, '_blank');
    // Opcionalmente, puedes revocar la URL después de un tiempo
    setTimeout(() => window.URL.revokeObjectURL(url), 5000);
  }

  private async saveAndOpenFile(response: Blob, ticketId: number) {
    try {
      // Convertir el archivo Blob a Base64
      const base64data = await this.convertBlobToBase64(response);

      // Guardar el archivo en el sistema de archivos local
      const fileName = `Ticket_${ticketId}.pdf`;
      const writeResult: WriteFileResult = await Filesystem.writeFile({
        path: fileName,
        data: base64data,
        directory: Directory.Documents,
      });

      console.log('Archivo guardado en:', writeResult.uri);

      // Abrir el archivo después de guardarlo
      await this.fileOpener.open(writeResult.uri, 'application/pdf');
      console.log('Archivo abierto correctamente');
    } catch (error) {
      console.error('Error procesando el archivo:', error);
    }
  }

  private async convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result as string).split(',')[1]); // Extraer contenido Base64
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

}
