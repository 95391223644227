import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom } from "rxjs";
import { TicketAction } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const TICKET_ACTIONS_KEY = 'ticketactions';

@Injectable({
  providedIn: 'root'
})
export class TicketActionService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  getTicketActions(): Observable<TicketAction[]> {
    const url = `${API_URL}/${VERSION}/ticketaction/ticketactions`;
    return this.http.get<TicketAction[]>(url).pipe(
      map(tickets => {
        this.storageService.set(TICKET_ACTIONS_KEY, tickets);
        return tickets;
      })
    );
  }

  getAllTicketActions(): Observable<TicketAction[]> {
    return from(this.storageService.get(TICKET_ACTIONS_KEY));
  }

  getTicketActionById(id: number): Observable<TicketAction> {
    return from(this.storageService.get(TICKET_ACTIONS_KEY)).pipe(
      map(tickets => tickets.find(ticket => ticket.id == id))
    );
  }

  getTicketActionsByTicketId(ticketId: number): Observable<TicketAction[]> {
    return from(this.storageService.get(TICKET_ACTIONS_KEY)).pipe(
      map(tickets => tickets.filter(ticket => ticket.ticketId == ticketId))
    );
  }

  async add(ticket: TicketAction): Promise<TicketAction> {
    const url = `${API_URL}/${VERSION}/ticketaction/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {

      return lastValueFrom(this.http.post<TicketAction>(url, ticket)).then(
        async response => {
          await this.addTicket(response);
          return response;
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
          return null;
        }
      );
    }
  }

  addTicket(newTicket: TicketAction) {
    return this.storageService.get(TICKET_ACTIONS_KEY).then((tickets: any[]) => {
      if (tickets) {
        tickets.unshift(newTicket);
        return this.storageService.set(TICKET_ACTIONS_KEY, tickets);
      }
      return this.storageService.set(TICKET_ACTIONS_KEY, [newTicket]);
    });
  }

  update(ticket: TicketAction) {
    const url = `${API_URL}/${VERSION}/ticketaction/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {
      this.http.post(url, ticket).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
        }
      );
    }
    return from(this.updateTicket(ticket));
  }
      
  updateTicket(ticket: TicketAction){
    const editedTicket = {
      ...ticket,
    };
    return this.storageService.get(TICKET_ACTIONS_KEY).then((tickets: any[]) => {
      const index = tickets.findIndex(tickets => tickets.id === editedTicket.id);
      tickets[index] = editedTicket;
      return this.storageService.set(TICKET_ACTIONS_KEY, tickets);
    });
  }

  delete(ticketId: number) {
    const url = `${API_URL}/${VERSION}/ticketaction/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticketId);
    } else {
      this.http.post(url, ticketId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticketId);
        }
      );
    }
    return from(this.deleteTicket(ticketId));
  }

  deleteTicket(ticketId: number) {
    return this.storageService.get(TICKET_ACTIONS_KEY).then(tickets => {
      const index = tickets.findIndex(ticket => ticket.id === ticketId);
      tickets.splice(index, 1);
      return this.storageService.set(TICKET_ACTIONS_KEY, tickets);
    });
  }
}
