import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { Requester } from '../01-models';
import { map } from "rxjs/operators";
import { StorageService } from './storage.service';

const REQUESTERS_KEY = 'requesters';

@Injectable({
  providedIn: 'root'
})
export class RequesterService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
    ) {}

  getRequesters(): Observable<Requester[]> {
    const url = `${API_URL}/${VERSION}/requester/requesters`;
    return this.http.get<Requester[]>(url).pipe(
      map(requesters => {
        this.storageService.set(REQUESTERS_KEY, requesters);
        return requesters;
      })
    );
  }

  getAllRequesters(): Observable<Requester[]> {
    return from(this.storageService.get(REQUESTERS_KEY));
  }

  getRequestersByCompanyId(companyId: number): Observable<Requester[]> {
    return from(this.storageService.get(REQUESTERS_KEY)).pipe(
      map(requesters => requesters.filter(requester => requester.companyId == companyId)
      .sort((a, b) => a.requesterName.localeCompare(b.requesterName)))
    );
  }

  getRequesterById(id: number): Observable<Requester> {
    return from(this.storageService.get(REQUESTERS_KEY)).pipe(
      map(requesters => requesters.find(requester => requester.id == id))
    );
  }
}
