import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { importsStandalone } from 'src/app/05-shared/utils/ImportsStandalone';
import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Storage } from '@ionic/storage';
import { API_URL, VERSION } from "../../environments/environment";
import { map } from "rxjs/operators";
import { Auth, User } from '../01-models';
import { StorageService } from './storage.service';
import { OfflineManagerService } from './offline-manager.service';
import { SynchronizationService } from './synchronization.service';

const AUTH_KEY = 'auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authState$: BehaviorSubject<Auth | null> = new BehaviorSubject<Auth | null>(null);

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform,
    private storageService: StorageService,
    private offlineManager: OfflineManagerService
  ) {
    this.platform.ready().then(() => {
      this.checkAuth();
    });
  }

  checkAuth() {
    this.storageService.get(AUTH_KEY).then(auth => {
      if (auth) {
        this.authState$.next(auth);
      }
    })
  }

  fingerprintLogin(email: string): Observable<Auth> {
    return this.http
      .post<Auth>(`${API_URL}/${VERSION}/auth/fingerprintlogin`, {
        email
      })
      .pipe(
        map(auth => {
          if (auth.user.email && auth.authToken) {
            this.storageService.set(AUTH_KEY, auth).then(() => {
              this.authState$.next(auth);
            });
          }

          return auth;
        })
      );
  }

  authUser(userId: number, password: string): Observable<Auth> {
    return this.http
      .post<Auth>(`${API_URL}/${VERSION}/auth/authuser`, {
        userId,
        password
      });
  }

  login(email: string, password: string): Promise<Auth> {
    this.offlineManager.removeOperations();
    return lastValueFrom(this.http
      .post<Auth>(`${API_URL}/${VERSION}/auth/login`, {
        email,
        password
      })).then(async (auth) => {
        //console.log("AuthService: ", auth);
        if (auth.user.email && auth.authToken) {
          await this.storageService.set(AUTH_KEY, auth).then(() => {
            this.authState$.next(auth);
            return auth;
          });
        }
        return auth;
      });

  }

  setUser(user: User) {
    const auth = this.authState$.value;
    if (auth) {
      const updatedAuth = { ...auth, user };
      this.storageService.set(AUTH_KEY, updatedAuth).then(() => {
        this.authState$.next(updatedAuth);
      });
    } else {
      console.error('No auth state available');
    }
  }

  logout() {
    this.offlineManager.removeOperations();
    return this.storageService.remove(AUTH_KEY).then(() => {
      this.authState$.next(null);
      this.storageService.clear();
    });
  }

  signup(username: string, workerDni: string, password: string, email: string, phone: string): Observable<Auth> {
    return this.http
      .post<Auth>(`${API_URL}/${VERSION}/auth/signup`, {
        email,
        phone,
        username,
        workerDni,
        password,
      })
      .pipe(
        map(auth => {
          if (auth.user.email && auth.authToken) {
            this.storageService.set(AUTH_KEY, auth).then(() => {
              this.authState$.next(auth);
            });
          }

          return auth;
        })
      );
  }

  isAuthenticated(): boolean {
    return this.authState$.value !== null;
  }

  getUserRemote(): Promise<User> {
    return lastValueFrom(this.http.post<User>(`${API_URL}/${VERSION}/auth/getMe`, {}));
  }

  getUser(): User | null {
    const auth = this.authState$.value;
    return auth ? auth.user : null;
  }

  getToken(): string | null {
    const auth = this.authState$.value;
    return auth ? auth.authToken : null;
  }

  updateUser(user: User) {
    const auth = this.authState$.value;
    if (auth) {
      const updatedAuth = { ...auth, user };
      this.storageService.set(AUTH_KEY, updatedAuth).then(() => {
        this.authState$.next(updatedAuth);
      });
    } else {
      console.error('No auth state available');
    }
  }
}
