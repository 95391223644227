import { Injectable } from '@angular/core';
import { Drivers, Storage } from '@ionic/storage';
import { STORAGE_REQ_KEY } from './offline-manager.service';
import { SERVICE_SV_EXTRA_KEY } from '../05-shared/utils/Contanst';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storedreqSubject = new BehaviorSubject(null);
  private storagePermanentConfig: Storage | null = null;

  constructor(
    private storage: Storage
  ) { 
    this.initDatabasePermanentConfig();
    this.initDatabase();
  }
  private async initDatabase() {
    await this.storage.create();
  }

  private async initDatabasePermanentConfig() {
    this.storagePermanentConfig = new Storage({
      name: '__kflow_permanent_config',
      storeName: '__kflow_permanent_config',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    this.storagePermanentConfig.create().then(() => {
      console.log('Database initialized');
    }).catch((error) => {
      console.error('Error initializing database', error);
    });
  }

  set(key: string, value: any) {
    if (key === SERVICE_SV_EXTRA_KEY) {
      if (!Array.isArray(value)) {
        throw new Error('No se puede guardar un array en el storage');
      }
    }
    if (key === STORAGE_REQ_KEY) {
      this.storedreqSubject.next(value);
    }
    return this.storage.set(key, JSON.stringify(value));
  }

  async getValueForKey(key: string) {
    try {
      const result = await this.storage.get(key);
      if (result === null || result === undefined) {
        // Opcional: Inicializa la clave con un valor predeterminado
        const defaultValue = []; // o cualquier valor predeterminado que necesites
        await this.storage.set(key, defaultValue);
        return defaultValue;
      }
      return result;
    } catch (error) {
      console.error(`ERROR: No se pudo obtener el valor para la clave ${key}.`, error);
      throw error; // O maneja el error de otra manera
    }
  }

  async get(key: string) {
    const exits = await this.getValueForKey(key);
    if (exits) {
      return this.storage.get(key).then((result) => {
        if (result === null || result === undefined || typeof result !== 'string') {
          return null;
        }
        return JSON.parse(result);
      }).catch((error) => {
        console.error('Error getting item from database', error);
        return null;
      });
    }
  }

  remove(key: string) {
    return this.storage.remove(key);
  }

  clear() {
    return this.storage.clear();
  }

  setStoragePermanentConfig(key: string, value: any) {
    return this.storagePermanentConfig.set(key, JSON.stringify(value));
  }

  async getStoragePermanentConfig(key: string) {
    try {
      const result = await this.storagePermanentConfig.get(key);
      if (result === null || result === undefined) {
        // Opcional: Inicializa la clave con un valor predeterminado
        const defaultValue = false; // o cualquier valor predeterminado que necesites
        await this.storagePermanentConfig.set(key, defaultValue);
        return defaultValue;
      }
      return result;
    } catch (error) {
      console.error(`ERROR: No se pudo obtener el valor para la clave ${key}.`, error);
      throw error; // O maneja el error de otra manera
    }
  }



}
