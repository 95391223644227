import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from 'rxjs';
import { Tag } from '../01-models';
import { NetworkService, ConnectionStatus } from './network.service';
import { map } from "rxjs/operators";
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const TAGS_KEY = 'tags';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
  ) {}

  getTags(): Observable<Tag[]> {
    let url = `${API_URL}/${VERSION}/tag/tags`;
    return this.http.get<Tag[]>(url).pipe(
      map(tags => {
        this.storageService.set(TAGS_KEY, tags);
        return tags;
      })
    );
  }

  add(tag) {
    const url = `${API_URL}/${VERSION}/tag/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', tag);
    } else {
      this.http.post(url, tag).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', tag);
        }
      );
    }
    return from(this.addTag(tag));
  }

  getTagById(tagId: string): Observable<Tag> {
    return from(this.storageService.get(TAGS_KEY)).pipe(
      map(tags => tags.find(tag => tag.tagId == tagId))
    );
  }

  addTag(tag: Tag) {
    return this.storageService.get(TAGS_KEY).then((tags: Tag[]) => {
      if (tags) {
        tags.push(tag);
        return this.storageService.set(TAGS_KEY, tags);
      }
      return this.storageService.set(TAGS_KEY, [tag]);
    });
  }
}
