import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GeofencingService {

  officeLocation = { lat: 40.7128, lng: -74.0060 }; // Coordenadas de la oficina

  constructor() { }

  // Método para iniciar geofencing
  async startGeofencing() {
    try {
      const position = await Geolocation.getCurrentPosition();
      const userLat = position.coords.latitude;
      const userLng = position.coords.longitude;

      const distance = this.getDistanceFromLatLonInKm(userLat, userLng, this.officeLocation.lat, this.officeLocation.lng);

      if (distance < 0.2) { // A menos de 200 metros
        console.log('Empleado cerca de la oficina');
        return true; // O cualquier otra acción que necesites
      } else {
        console.log('Empleado lejos de la oficina');
        return false;
      }
    } catch (error) {
      console.error('Error obteniendo la ubicación', error);
      return false;
    }
  }

  getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radio de la Tierra en km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distancia en km
  }

  deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }
}
