import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { Incidence } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const INCIDENCES_KEY = 'incidences';

@Injectable({
  providedIn: 'root'
})
export class IncidenceService {
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  add(incidence: Incidence) {
    const url = `${API_URL}/${VERSION}/incidence/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', incidence);
    } else {
      this.http.post(url, incidence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', incidence);
        }
      );
    }
    return from(this.addIncidence(incidence));
  }

  addIncidence(newIncidence: Incidence) {
    return this.storageService.get(INCIDENCES_KEY).then((incidences: any[]) => {
      if (incidences) {
        incidences.unshift(newIncidence);
        return this.storageService.set(INCIDENCES_KEY, incidences);
      }
      return this.storageService.set(INCIDENCES_KEY, [newIncidence]);
    });
  }

  update(incidence: Incidence) {
    const url = `${API_URL}/${VERSION}/incidence/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', incidence);
    } else {
      this.http.post(url, incidence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', incidence);
        }
      );
    }
    return from(this.updateIncidence(incidence));
  }
      
  updateIncidence(editedIncidence: Incidence){
    return this.storageService.get(INCIDENCES_KEY).then((incidences: any[]) => {
      const index = incidences.findIndex(incidences => incidences.id === editedIncidence.id);
      incidences[index] = editedIncidence;
      return this.storageService.set(INCIDENCES_KEY, incidences);
    });
  }

  getIncidences(): Observable<Incidence[]> {
    const url = `${API_URL}/${VERSION}/incidence/incidences`;
    return this.http.get<Incidence[]>(url).pipe(
      map(incidences => {
        this.storageService.set(INCIDENCES_KEY, incidences);
        return incidences;
      })
    );
  }

  getAllIncidences(): Observable<Incidence[]> {
    return from(this.storageService.get(INCIDENCES_KEY));
  }

  getIncidenceById(id: number): Observable<Incidence> {
    return from(this.storageService.get(INCIDENCES_KEY)).pipe(
      map(incidences => incidences.find(incidence => incidence.id == id))
    );
  }

  getIncidencesByWorkplace(companyId: number, workplaceNum: number): Observable<Incidence[]> {
    return from(this.storageService.get(INCIDENCES_KEY)).pipe(
      map(incidences => incidences.filter(incidence => incidence.companyId == companyId && incidence.workplaceNum == workplaceNum))
    );
  }

  getIncidencesByWorkplaces(workplaces: any[]): Observable<Incidence[]> {
    return from(this.storageService.get(INCIDENCES_KEY)).pipe(
      map(incidences => incidences.filter(incidence => workplaces.find(workplace => workplace.companyId === incidence.companyId && workplace.workplaceNum === incidence.workplaceNum)))
    );
  }

  delete(incidenceId: number) {
    const url = `${API_URL}/${VERSION}/incidence/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', incidenceId);
    } else {
      this.http.post(url, incidenceId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', incidenceId);
        }
      );
    }
    return from(this.deleteIncidence(incidenceId));
  }

  deleteIncidence(incidenceId: number) {
    return this.storageService.get(INCIDENCES_KEY).then(incidences => {
      const index = incidences.findIndex(incidence => incidence.id === incidenceId);
      incidences.splice(index, 1);
      return this.storageService.set(INCIDENCES_KEY, incidences);
    });
  }
}
