import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova } from '@ionic-native/core';
var FileOpener = /** @class */function (_super) {
  __extends(FileOpener, _super);
  function FileOpener() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  FileOpener.prototype.open = function (filePath, fileMIMEType) {
    return cordova(this, "open", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.prototype.uninstall = function (packageId) {
    return cordova(this, "uninstall", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.prototype.appIsInstalled = function (packageId) {
    return cordova(this, "appIsInstalled", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.prototype.showOpenWithDialog = function (filePath, fileMIMEType) {
    return cordova(this, "showOpenWithDialog", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.pluginName = "FileOpener";
  FileOpener.plugin = "cordova-plugin-file-opener2";
  FileOpener.pluginRef = "cordova.plugins.fileOpener2";
  FileOpener.repo = "https://github.com/pwlin/cordova-plugin-file-opener2";
  FileOpener.platforms = ["Android", "iOS", "Windows", "Windows Phone 8"];
  FileOpener.decorators = [{
    type: Injectable
  }];
  return FileOpener;
}(IonicNativePlugin);
export { FileOpener };
