import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { Cpd } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const CPDS_KEY = 'cpds';

@Injectable({
  providedIn: 'root'
})
export class CpdService {
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  add(newCpd: Cpd): Observable<Cpd> {
    const url = `${API_URL}/${VERSION}/cpd/add`;
    return this.http.post(url, newCpd);
  }

  addCpd(newCpd: Cpd): Observable<Cpd> {
    return from(this.storageService.get(CPDS_KEY).then((cpds: any[]) => {
      if (cpds) {
        cpds.unshift(newCpd);
        return this.storageService.set(CPDS_KEY, cpds);
      }
      return this.storageService.set(CPDS_KEY, [newCpd]);
    }));
  }

  update(editedCpd: Cpd) {
    const url = `${API_URL}/${VERSION}/cpd/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedCpd);
    } else {
      this.http.post(url, editedCpd).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedCpd);
        }
      );
    }
    return from(this.updateCpd(editedCpd));
  }
      
  updateCpd(editedCpd: Cpd){
    return this.storageService.get(CPDS_KEY).then((cpds: any[]) => {
      const index = cpds.findIndex(cpd => cpd.id === editedCpd.id);
      cpds[index] = editedCpd;
      return this.storageService.set(CPDS_KEY, cpds);
    });
  }

  getCpds(): Observable<Cpd[]> {
    const url = `${API_URL}/${VERSION}/cpd/cpds`;
    return this.http.get<Cpd[]>(url).pipe(
      map(cpds => {
        this.storageService.set(CPDS_KEY, cpds);
        return cpds;
      })
    );
  }

  getAllCpds(): Observable<Cpd[]> {
    return from(this.storageService.get(CPDS_KEY));
  }

  getCpdById(id: number): Observable<Cpd> {
    return from(this.storageService.get(CPDS_KEY)).pipe(
      map(cpds => cpds.find(cpd => cpd.id == id))
    );
  }

  getCpdByRef(ref: string): Observable<Cpd> {
    return from(this.storageService.get(CPDS_KEY)).pipe(
      map(cpds => cpds.find(cpd => cpd.ref == ref))
    );
  }

  getCpdByNum(num: number): Observable<Cpd> {
    return from(this.storageService.get(CPDS_KEY)).pipe(
      map(cpds => cpds.find(cpd => cpd.num == num))
    );
  }

  delete(cpdId: number) {
    const url = `${API_URL}/${VERSION}/cpd/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', cpdId);
    } else {
      this.http.post(url, cpdId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', cpdId);
        }
      );
    }
    return from(this.deleteCpd(cpdId));
  }

  deleteCpd(cpdId: number) {
    return this.storageService.get(CPDS_KEY).then(cpds => {
      const index = cpds.findIndex(cpd => cpd.id === cpdId);
      cpds.splice(index, 1);
      return this.storageService.set(CPDS_KEY, cpds);
    });
  }

  stateCloseCpdAndPresence(RefSVCPR: string) {
    const url = `${API_URL}/${VERSION}/cpd/stateCloseCpdAndPresence`;
    return this.http.post(url, {RefSVCPR});
  }
}

