import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { OfferService } from './offer.service';
import { IncidenceService } from './incidence.service';
import { ServiceSvContractService } from './service-sv-contract.service';
import { DependenceService } from './dependence.service';
import { UserService } from './user.service';
import { TaskService } from './task.service';
import { ServiceSVExtraService } from './service-sv-extra.service';
import { CpdService } from './cpd.service';
import { TicketService } from './ticket.service';
import { TicketActionService } from './ticket-action.service';
import { RequesterService } from './requester.service';
import { WorkplaceService } from './workplace.service';
import { PresenceService } from './presence.service';
import { ExpectedPresenceService } from './expected-presence.service';
import { NotificationService } from './notification.service';
import { StorageService } from './storage.service';
import { API_URL, VERSION } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { TableForSync } from '../01-models/table-for-sync';
import { NetworkService, ConnectionStatus } from '../02-services/network.service';
import { BeaconsService } from './beacons.service';

const SYNC_KEY = 'sync';

@Injectable({
  providedIn: 'root'
})
export class SynchronizationService {

  syncState$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private offerService = inject(OfferService);
  private serviceSaleOrderService = inject(ServiceSvContractService);
  private dependenceService = inject(DependenceService);
  private userService = inject(UserService);
  private taskService = inject(TaskService);
  private serviceSvService = inject(ServiceSVExtraService);
  private cpdService = inject(CpdService);
  private ticketService = inject(TicketService);
  private ticketActionService = inject(TicketActionService);
  private requesterService = inject(RequesterService);
  private workplaceService = inject(WorkplaceService);
  private presenceService = inject(PresenceService);
  private expectedPresenceService = inject(ExpectedPresenceService);
  private notificationService = inject(NotificationService);
  private beaconsService = inject(BeaconsService);
  private storageService = inject(StorageService);
  private http = inject(HttpClient);
  private loading = inject(LoadingService);

  syncNeedRefreshScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  flagLoading = false;


  constructor(
    private networkService: NetworkService
  ) {}

  async sendSyncByTable() {
    if (
      this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Online
    ){
      let flagSync = false
      const methodByTable = {
        'service-sale-orders': this.serviceSaleOrderService.getServiceSaleOrders(),
        'presences': this.presenceService.getPresences(),
        'expected-presences': this.expectedPresenceService.getExpectedPresences(),
        'dependences': this.dependenceService.getDependences(),
        'cpds': this.cpdService.getCpds(),
        'notifications': this.notificationService.getNotifications(),
        'offers': this.offerService.getOffers(),
        'requesters': this.requesterService.getRequesters(),
        'tasks': this.taskService.getTasks(),
        'tickets': this.ticketService.getTickets(),
        'ticket-actions': this.ticketActionService.getTicketActions(),
        'workplaces': this.workplaceService.getWorkplaces(),
        'work-orders': this.serviceSvService.getServiceSvExtra(),
        'work_categories': this.serviceSvService.getWorkCategories(),
        'users': this.userService.getUsers(),
        "beacons-wifi": this.beaconsService.getAllWifiRequest(),
        "beacons-nfc": this.beaconsService.getListNfcRequest(),
        "beacons-bluetooth": this.beaconsService.getListBluetoothRequest(),
        "beacons-gps": this.beaconsService.getListGeolocationRequest(),
      };

      const tablesForSync =  await lastValueFrom(this.syncKflowTablesVerify());
      this.setSyncDate();
      for (const element of tablesForSync) {
        if (element.mensaje === 'Necesita sincronización') {
          flagSync = true;
          const result = methodByTable[element.tabla];
          await lastValueFrom(result);
        }
      }

      if (flagSync) {
        this.syncNeedRefreshScreen.next(true);
        setTimeout(() => {
          this.syncNeedRefreshScreen.next(false);
        }, 5000);
      }
    }
  }


  setSyncDate() {
    const currentDateTime = Date.now();
    this.storageService.set(SYNC_KEY, currentDateTime).then(() => {
      this.syncState$.next(currentDateTime);
    });
  }

  public syncKflowTablesVerify(): Observable<TableForSync[]> {
    const date = new Date(this.syncState$.value);
      console.log('Sincronizar desde: ', date);
      const formattedDate = `${date.getFullYear()}-${this.zeroBefore(date.getMonth() + 1)}-${this.zeroBefore(date.getDate())} ${this.zeroBefore(date.getHours())}:${this.zeroBefore(date.getMinutes())}:00`;
      const url = `${API_URL}/${VERSION}/Sync/SyncronizacionKflowTablasResultado`;
      return this.http.post<TableForSync[]>(
        url, 
        new String(formattedDate),
        { headers: { 'Content-Type': 'application/json' } });
  }

  zeroBefore(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }


 
  
}
