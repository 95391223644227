import { Injectable } from '@angular/core';
import { CancelOptions, Channel, LocalNotifications } from '@capacitor/local-notifications';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  constructor() { }


  // Solicitar permisos para las notificaciones
  async requestPermissions() {
    const permission = await LocalNotifications.requestPermissions();
    return permission;
  }

  // Registrar tipos de acciones personalizados para notificaciones
  registerActionTypes() {
    LocalNotifications.registerActionTypes({
      types: [
        {
          id: 'MEETING_ACTIONS',
          actions: [
            {
              id: 'ACCEPT',
              title: 'Aceptar'
            },
            {
              id: 'DECLINE',
              title: 'Rechazar',
              destructive: true
            },
            {
              id: 'SNOOZE',
              title: 'Posponer',
              input: true,
              inputButtonTitle: 'Enviar',
              inputPlaceholder: 'Ingresa el tiempo de posponer'
            }
          ]
        }
      ]
    });
  }

  // Programar una notificación
  async scheduleNotification(id: number, title: string, body: string, time: Date) {
    await LocalNotifications.schedule({
      notifications: [
        {
          id,
          title,
          body,
          summaryText: "Esto es resumen",
          largeBody: "Aquí iria texto largo largo, muy largo...",
          schedule: { at: time },
          sound: null,
          attachments: null,
          smallIcon: 'res://drawable/logo_icon',
          largeIcon: 'res://drawable/logo_icon',
          actionTypeId: "MEETING_ACTIONS",
          extra: null
        }
      ]
    });
  }

  // Cancelar una notificación específica
  async cancelNotification(id: number) {
    const cancelOptions: CancelOptions = { notifications: [{ id }] };
    await LocalNotifications.cancel(cancelOptions);
  }

  // Cancelar todas las notificaciones
  async cancelAllNotifications() {
    // Obtener todas las notificaciones programadas
    const { notifications } = await LocalNotifications.getPending();

    // Crear una lista con los IDs de las notificaciones programadas
    const notificationIds = notifications.map(notification => ({ id: notification.id }));

    // Cancelar todas las notificaciones usando la lista de IDs
    if (notificationIds.length > 0) {
      await LocalNotifications.cancel({ notifications: notificationIds });
    }
  }

  // Escuchar cuando se activa una notificación
  listenToNotifications() {
    LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      console.log('Notification action performed', notification);
    });
  }

  async removeAllDeliveredNotifiations() {
    await LocalNotifications.removeAllDeliveredNotifications;
  }

  async getDeliveredNotifications() {
    LocalNotifications.getDeliveredNotifications().then((res) => {
      alert(JSON.stringify(res))
    })
  }

  async createChannel(channelName, description) {
    let channel1: Channel = {
      id: "default",
      description: description,
      name: channelName,
      importance: 5,
      visibility: 1
    }

    try {

      await LocalNotifications.createChannel(channel1);

    } catch (exp) {

      console.log(exp);
    }
  }

  async listChannel() {
    await LocalNotifications.listChannels().then((res) => {
      alert(JSON.stringify(res))
    })
  }

}
