import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { ServiceSvContract } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const SERVICE_SV_CONTRACT_KEY = 'service-sv-contract';

@Injectable({
  providedIn: 'root'
})
export class ServiceSvContractService {
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  getServiceSaleOrders(): Observable<ServiceSvContract[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/servicesvcontracts`;
    return this.http.get<ServiceSvContract[]>(url).pipe(
      map(serviceSaleOrders => {
        this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSaleOrders);
        return serviceSaleOrders;
      })
    );
  }


  getAllServiceSvContract(): Observable<ServiceSvContract[]> {
    // Obtener los contratos de servicio desde el almacenamiento
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContracts => { 
        // Ordenar los contratos de servicio por año, luego por mes, y finalmente por nombre de la empresa
        const sortedServices = serviceSvContracts.sort((a, b) => {
          return b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName);
        });
  
        // Eliminar duplicados agrupando los contratos por 'id'
        const uniqueServices = [];
        sortedServices.forEach(service => {
          // Si no existe un servicio con el mismo 'id', lo añadimos a la lista de servicios únicos
          if (!uniqueServices.some(existingService => existingService.id === service.id)) {
            uniqueServices.push(service);
          }
        });
  
        // Retornar la lista de contratos de servicio únicos y ordenados
        return uniqueServices;
      })
    );
  }
  

  getServiceSaleOrdersByDni(workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => workerDni && serviceSaleOrder.workerDni == workerDni))
    );
  }

  getServiceSaleOrdersBySubstituteDni(workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => workerDni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(workerDni) !== -1))
    );
  }

  getServiceSaleOrderByIds(ids: string[]): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => ids.includes(serviceSaleOrder.serviceSvRef)))
    );
  }

  getServiceSaleOrdersByWorker(workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => { 
        const services = serviceSaleOrders.filter(serviceSaleOrder => (workerDni && serviceSaleOrder.dni == workerDni) || (workerDni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(workerDni) !== -1))
                                                .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))
        
        const servicesGroupByWorkplace = [];                                        
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }

  serviceSvContractByTeamLead(workerDniLead: string/*, serviceIds: string[]*/): Observable<ServiceSvContract[]> {
  
    // Obtener los contratos de servicio desde el almacenamiento
    
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map((serviceContracts: ServiceSvContract[]) => {
        // Filtrar los contratos de servicio basados en las siguientes condiciones:
        
      const filteredServices = serviceContracts.filter(serviceSvContract => {
          const isLead = workerDniLead && serviceSvContract.dni === workerDniLead;
          const isSubstitute = workerDniLead && serviceSvContract.substitutes?.includes(workerDniLead);
          //const isReferenced = serviceIds.includes(serviceSvContract.serviceSvRef);
          const isTeamLead = serviceSvContract.teamLeads?.includes(workerDniLead);
  
          // Retornar verdadero si cumple alguna de las condiciones anteriores
          return isLead || isSubstitute /*|| isReferenced*/ || isTeamLead;
        });
  
        // Ordenar los contratos de servicio por año, luego por mes, y finalmente por el nombre de la empresa
        const sortedServices = filteredServices.sort((a, b) => {
          return b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName);
        });
  
        // Eliminar duplicados agrupando por 'id' del contrato de servicio
        const uniqueServices = [];
        sortedServices.forEach(service => {
          // Si no existe un servicio con el mismo 'id', lo añadimos al arreglo
          if (!uniqueServices.some(existingService => existingService.id === service.id)) {
            uniqueServices.push(service);
          }
        });
  
        // Devolver los contratos de servicio únicos y ordenados
        return uniqueServices;
      })
    );
  }

  getServiceSaleOrderByIdAndDni(id: string, workerDni: string): Observable<ServiceSvContract> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      //map(serviceSaleOrders => serviceSaleOrders.find(serviceSaleOrder => workerDni && serviceSaleOrder.workerDni == workerDni && serviceSaleOrder.id == id))
      map(serviceSaleOrders => serviceSaleOrders.find(serviceSaleOrder => serviceSaleOrder.id == id))
    );
  }

  getServiceSaleOrdersByIdAndDni(id: string, workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => serviceSaleOrder.id === id))
    );
  }

  getServiceSaleOrderById(id: string): Observable<ServiceSvContract> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.find(serviceSaleOrder => serviceSaleOrder.id == id))
    );
  }

  getServiceSaleOrdersByCompanyId(companyId: number): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => {
        const services = serviceSaleOrders.filter(serviceSaleOrder => serviceSaleOrder.companyId == companyId)
                                              .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))
                            
        const servicesGroupByWorkplace = [];                                        
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }

  getServiceSaleOrdersByWorkplace(companyId: number, workplaceNum: number): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => serviceSaleOrder.companyId == companyId && serviceSaleOrder.workplaceNum == workplaceNum))
    );
  }

  update(serviceSaleOrder: ServiceSvContract) {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSaleOrder);
    } else {
      this.http.post(url, serviceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSaleOrder);
        }
      );
    }
    return from(this.updateServiceSvContract(serviceSaleOrder));
  }
      
  updateServiceSvContract(serviceSaleOrder: ServiceSvContract){
    return this.storageService.get(SERVICE_SV_CONTRACT_KEY).then((serviceSvContracts: ServiceSvContract[]) => {
      const index = serviceSvContracts.findIndex(serviceSvContractItem => serviceSvContractItem.id === serviceSaleOrder.id);
      serviceSvContracts[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSvContracts);
    });
  }

  signature(editedServiceSaleOrder: ServiceSvContract) {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/signature`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
    } else {
      this.http.post(url, editedServiceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
        }
      );
    }
    return from(this.signatureServiceSaleOrder(editedServiceSaleOrder));
  }
      
  signatureServiceSaleOrder(serviceSaleOrder: ServiceSvContract){
    return this.storageService.get(SERVICE_SV_CONTRACT_KEY).then((serviceSaleOrders) => {
      const index = serviceSaleOrders.findIndex(serviceSaleOrders => serviceSaleOrders.id === serviceSaleOrder.id);
      serviceSaleOrders[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSaleOrders);
    });
  }

  addCpd(serviceSaleOrder: ServiceSvContract) {
    const editedServiceSaleOrder = {...serviceSaleOrder}
    const url = `${API_URL}/${VERSION}/ServiceSvContract/addCpdNum`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
    } else {
      this.http.post(url, editedServiceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
        }
      );
    }
    return from(this.addCpdNum(editedServiceSaleOrder));
  }
      
  addCpdNum(serviceSaleOrder: ServiceSvContract){
    return this.storageService.get(SERVICE_SV_CONTRACT_KEY).then((serviceSaleOrders) => {
      const index = serviceSaleOrders.findIndex(serviceSaleOrders => serviceSaleOrders.id === serviceSaleOrder.id);
      serviceSaleOrders[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSaleOrders);
    });
  }
}
